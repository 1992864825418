.our-image {
    max-height: 100vw;
  }
@media screen and (min-width: 768px) {
  .our-image {
    max-height: 406px;
  }
}
.program-image {
  width:80px;
}